import { Title } from '@solidjs/meta';
import {
	BreadcrumbItem,
	Button,
	Container,
	Details,
	Heading,
	HorizontalRule,
	Label,
	Link,
	Page,
	Radio,
	RadioGroup,
	Section,
	Summary,
	Tab,
	TabList,
	TabPanel,
	Tabs,
} from '@troon/ui';
import { createAsync } from '@solidjs/router';
import { createSignal, For, Match, Show, Suspense, Switch } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Hero } from '../../components/hero/photo';
import { getConfigValue } from '../../modules/config';
import { Grid, GridFive, GridSeven } from '../../components/layouts/grid';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { createFragment } from '../../graphql/create-fragment';
import { createContentfulRequest } from '../content/[model]/_client';
import { RichText } from '../../components/rich-text';
import type { FragmentType } from '../../graphql';
import type { RouteDefinition } from '@solidjs/router';

export default function RewardsRedemptionOptions() {
	const data = createAsync(() => getData({}), { deferStream: true });
	const [pointsType, setPointsType] = createSignal('free');
	const rules = createAsync(async () => getEntry('rewards-redemption-rules'), { deferStream: true });

	return (
		<>
			<Title>Troon Rewards Redemption Options | Troon</Title>
			<Hero
				src={`${getConfigValue('IMAGE_HOST')}/digital/hero/rewards.png`}
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/rewards">Troon Rewards</BreadcrumbItem>
						<BreadcrumbItem href="/rewards/redemption">Redemption Options</BreadcrumbItem>
					</>
				)}
			>
				<Heading as="h1">Redemption Options</Heading>
			</Hero>

			<Container>
				<Page>
					<Grid>
						<GridSeven>
							<Section>
								<Heading as="h2">Redeeming rounds of golf with Troon Rewards</Heading>
								<p>
									Members can use their Troon Rewards points towards one (1) complimentary round of golf based on the
									redemption table/schedule. Rounds of golf can also be redeemed by using a combination of Troon Rewards
									points and cash (based on the redemption chart).
								</p>

								<Button as={Link} href="#options" class="size-fit shrink">
									View courses
								</Button>
							</Section>
						</GridSeven>

						<GridFive>
							<Section class="rounded border border-neutral bg-neutral-100 p-6 text-sm text-neutral-900">
								<Suspense>
									<Show when={rules()?.fields.content}>{(content) => <RichText document={content()} />}</Show>
								</Suspense>
							</Section>
						</GridFive>
					</Grid>
					<HorizontalRule />

					<Section>
						<Heading as="h2" id="options">
							Tee Time Redemption Options
						</Heading>

						<Tabs>
							<Section>
								<TabList>
									<Tab value="domestic">Domestic Locations</Tab>
									<Tab value="international">International Locations</Tab>
								</TabList>

								<RadioGroup name="display" class="flex-row gap-x-8" onSelect={setPointsType}>
									<Label class="sr-only">Rewards Redemption Option Type</Label>
									<Radio value="free" checked>
										<Label>Free with Points</Label>
									</Radio>
									<Radio value="half">
										<Label>Points + Half</Label>
									</Radio>
								</RadioGroup>

								<For each={Object.entries(data()?.chart ?? {})}>
									{([key, regions]) => <RedemptionTable key={key} pointsType={pointsType()} regions={regions} />}
								</For>
							</Section>
						</Tabs>
					</Section>
				</Page>
			</Container>
		</>
	);
}

function RedemptionTable(props: {
	key: string;
	pointsType: string;
	regions: Array<FragmentType<typeof RewardRegionFragment>>;
}) {
	const regions = createFragment(RewardRegionFragment, props, 'regions');
	return (
		<TabPanel value={props.key}>
			<div class="divide-y divide-neutral border border-neutral">
				<For each={regions}>
					{(region) => (
						<Details class="w-full overflow-hidden">
							<Summary class="px-4 py-6 text-lg font-semibold group-open:mb-0 group-open:pb-4">
								{region.regionName}
							</Summary>
							<div class="overflow-x-auto">
								<table class="relative w-full">
									<thead>
										<tr>
											<th
												class="sticky left-0 border-e border-white bg-brand-700 px-3 py-2.5 text-start font-semibold uppercase text-white"
												colSpan={2}
											>
												Course
											</th>
											<For each={[...Array(12).keys()]}>
												{(m) => (
													<th class="border-x border-white bg-brand-700 px-3 py-2.5 text-center font-semibold uppercase text-white">
														{dayjs(`2024-${(m + 1) % 12}`).format('MMM')}
													</th>
												)}
											</For>
										</tr>
									</thead>
									<tbody>
										<For each={region.entries}>
											{(facility) => (
												<For each={facility.courses}>
													{(course, i) => (
														<tr>
															<Show when={i() === 0}>
																<th
																	scope="row"
																	colSpan={facility.courses.length === 1 ? 2 : 1}
																	rowSpan={facility.courses.length}
																	class="sticky left-0 w-32 min-w-32 max-w-32 border border-s-0 border-neutral bg-neutral-100 px-6 py-4 text-start font-normal md:min-w-48 md:max-w-48"
																>
																	<div class="flex flex-col gap-1">
																		<Link
																			href={`/course/${facility.slug}`}
																			class="text-base font-semibold after:absolute after:inset-0"
																		>
																			{facility.name}
																		</Link>
																		<span class="text-sm font-medium">{facility.location}</span>
																	</div>
																</th>
															</Show>
															<Show when={facility.courses.length > 1}>
																<th
																	scope="row"
																	class="sticky left-32 border border-s-0 border-neutral bg-neutral-100 px-6 py-4 text-start font-normal md:left-48"
																>
																	{course.courseName}
																</th>
															</Show>
															<For
																each={[...Array(12).keys()].map((m) =>
																	course.months.find((month) => month.month === m + 1),
																)}
															>
																{(month) => (
																	<td class="text-nowrap border border-neutral px-6 py-4 text-center">
																		<Show when={month} fallback="–">
																			{(month) => (
																				<Switch fallback="–">
																					<Match when={props.pointsType === 'free' && month().freePoints}>
																						{points(month().freePoints as number)} pts
																					</Match>
																					<Match when={props.pointsType === 'half'}>
																						{points(month().halfOffPoints as number)} pts +{' '}
																						{month().halfOffPrice?.displayValue}
																					</Match>
																				</Switch>
																			)}
																		</Show>
																	</td>
																)}
															</For>
														</tr>
													)}
												</For>
											)}
										</For>
									</tbody>
								</table>
							</div>
						</Details>
					)}
				</For>
			</div>
		</TabPanel>
	);
}

export const route = { info: { nav: { hero: true } }, preload: () => getData({}) } satisfies RouteDefinition;

const query = gql(`query rewardsRedemptionPage {
  chart: rewardRedemptionChart {
    domestic: domesticRegions {
      ...RewardRegion
    }
    international: internationalRegions {
      ...RewardRegion
    }
  }
}`);

const RewardRegionFragment = gql(`fragment RewardRegion on FacilityRewardRedemptionRegion {
  regionName
  entries {
    name: facilityName
    slug: facilitySlug
    location
		courses {
			courseName,
			months {
	  		month
				halfOffPrice { displayValue }
				halfOffPoints
				freePoints
			}
		}
  }
}`);

const getData = cachedQuery(query);

const points = Intl.NumberFormat('en-US').format;

const getEntry = createContentfulRequest('plain-document');
